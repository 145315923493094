// @ts-nocheck
import * as types from "../../constants";
import { PatientI } from "../../types/Dashboard";

function sortBy(key: any, cb) {
  if (!cb) cb = () => 0;
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(a, b));
}

function sortByDesc(key: any, cb) {
  if (!cb) cb = () => 0;
  return (b, a) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(b, a));
}

function orderBy(keys: any, orders) {
  let cb = () => 0;
  keys.reverse();
  orders.reverse();
  for (const [i, key] of keys.entries()) {
    const order = orders[i];
    if (order == "asc") {
      cb = sortBy(key, cb);
    } else if (order == "desc") {
      cb = sortByDesc(key, cb);
    } else {
      throw new Error(`Unsupported order "${order}"`);
    }
  }
  return cb;
}

export type PatientsReducerType = {
  patients: PatientI[];
  isLoading: boolean;
  isPatientLoading: boolean;
  patientMPR: Array<any>;
};
// TODO: REMOVE THIS OLD REDUCER
export default function reducer(
  state = {
    isLoading: true,
    isPatientLoading: true,
    patients: [],
    patientMPR: [],
  } as PatientsReducerType,
  actions: PatientsReducerType & { type: string }
) {
  switch (actions.type) {
    case types.PATIENTS_FETCH_REQUEST:
      return {
        ...state,
        patients: [],
        isLoading: true,
      };

    case types.PATIENTS_FETCH_SUCCESS:
      return {
        ...state,
        patients: actions.patients.sort(orderBy(
          ["medId", "firstName"],
          ["desc", "asc"]
        )),
        isLoading: false,
      };

    case types.PATIENTS_FETCH_FAILURE:
      return {
        ...state,
        patients: [],
        isLoading: false,
      };
    case types.PATIENT_MPR_FETCH_REQUEST:
      return {
        ...state,
        patientMPR: [],
        isPatientLoading: true,
      };

    case types.PATIENT_MPR_FETCH_SUCCESS:
      return {
        ...state,
        patientMPR: actions.patientMPR,
        isPatientLoading: false,
      };

    case types.PATIENT_MPR_FETCH_FAILURE:
      return {
        ...state,
        patientMPR: [],
        isPatientLoading: false,
      };

    default:
      return state;
  }
}
