import React from "react";

import async from "../components/Async";


// Auth components
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const ClinicianDashboard = async(
  () => import("../pages/ClinicianDashboard/ClinicianDashboard")
);
const ClinicianPatientReport = async(
  () => import("../pages/ClinicianPatientReport/index")
);
const ReassignPhysician = async(
  () => import("../pages/ReassignPhysician/ReassignPhysician")
);
const ManagePractices = async(
  () => import("../pages/ManagePractices/ManagePractices")
);
const ManagePhysicianProfile = async(
  () => import("../pages/ManagePhysicianProfile/ManagePhysicianProfile")
);
const ManageCaregiverProfile = async(
  () => import("../pages/ManageCaregiverProfile/ManageCaregiverProfile")
);
const NewPractice = async(() => import("../pages/ManagePractices/NewPractice"));
const ManagePractice = async(
  () => import("../pages/ManagePractices/ManagePractice")
);
const VewPhysician = async(
  () => import("../pages/ManagePhysicians/ViewPhysician")
);
const NewPhysician = async(
  () => import("../pages/ManagePhysicians/NewPhysician")
);
const PatientScript = async(
  () => import("../pages/PatientScript/PatientScript")
);
const ManagePatient = async(
  () => import("../pages/ManagePatient/ManagePatient")
);
const ManagePhysicians=async(()=>import("../pages/ManagePhysicians/ManagePhysicians"))
const SystemActivity = async(
  () => import("../pages/SystemActivity/SystemActivity")
);
const ManagePatientVanderbilts = async(
  () => import("../pages/ManagePatientsVanderbilts/ManagePatientsVanderbilts")
);
const Reports = async(() => import("../pages/Reports/Reports"));
const Inventory = async(() => import("../pages/Inventory"));
const ManagePatientAdhsrs = async(() => import("../pages/ADHSRS/manage"));
const CreatePatientAdhsrs = async(() => import("../pages/ADHSRS/new"));
const ViewPatientAdhsrs = async(() => import("../pages/ADHSRS/view"));
const EditPatientAdhsrs = async(() => import("../pages/ADHSRS/edit"));
const Headset=async(()=>import("../pages/Inventory/InventoryFormFields"))
// Landing
const Landing = async(() => import("../pages/presentation/Landing"));

// Vanderbilt
const Vanderbilt = async(() => import("../pages/vanderbilt/vanderbilt"));
const Survey = async(() => import("../pages/Survey/New"));

const EditVanderbilt = async(
  () => import("../pages/vanderbilt/editVanderbilt")
);
const EditSurvey = async(() => import("../pages/Survey/Edit"));

const ViewVanderbilt = async(
  () => import("../pages/vanderbilt/ViewVanderbilt")
);
const ViewSurvey = async(() => import("../pages/Survey/View"));

const ViewSPRChart = async(() => import("../pages/SBRCharts/ViewSPRChart"));

const DocumentsErrors = async(
  () => import("../pages/DocumentsErrors/DocumentsErrors")
);
const PerformanceReportDraft = async(() => import("../pages/PerformanceReportDraft"));
const AdherenceDraft = async(() => import("../pages/AdherenceDraft"));
const MPRList = async(() => import("../pages/ActivityDraft"));

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  containsHome: true,
  children: [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: ClinicianDashboard,
    },
    {
      path: "/patient/:patientId",
      name: "Clinician Patient Report",
      component: ClinicianPatientReport,
    },
    {
      path: "/patient/:patientId/re-assign",
      name: "Re-assign Physician",
      component: ReassignPhysician,
    },
    {
      path: "/dashboard/patient-script",
      name: "PatientScript",
      component: PatientScript,
    },
    {
      path: "/dashboard/manage-practices",
      name: "ManagePractices",
      component: ManagePractices,
    },
    {
      path: "/dashboard/manage-physician-profile",
      name: "ManagePractices",
      component: ManagePhysicianProfile,
    },
    {
      path: "/dashboard/manage-caregiver-profile",
      name: "ManagePractices",
      component: ManageCaregiverProfile,
    },
    {
      path: "/dashboard/manage-patient",
      name: "ManagePatient",
      component: ManagePatient,
    },
    {
      path: "/dashboard/activity",
      name: "SystemActivity",
      component: SystemActivity,
    },
    {
      path: "/dashboard/manage-patients/patient/:patientId/vanderbilts",
      name: "ManagePatientVanderbilts",
      component: ManagePatientVanderbilts,
    },
    {
      path: "/dashboard/reports",
      name: "Reports",
      component: Reports,
    },
    {
      path: "/dashboard/inventory",
      name: "Inventory",
      component: Inventory,
    },
    {
      path:
        "/dashboard/manage-patients/patient/:patientId/vanderbilts/new-vanderbilt",
      name: "NewVanderbilts",
      component: Vanderbilt,
    },
    {
      path: "/dashboard/manage-patients/patient/:patientId/surveys/new-survey",
      name: "NewPatientSurvey",
      component: Survey,
    },
    {
      path:
        "/dashboard/manage-patients/patient/:patientId/vanderbilts/edit-vanderbilt/:vanderbiltId",
      name: "EditVanderbilts",
      component: EditVanderbilt,
    },
    {
      path:
        "/dashboard/manage-patients/patient/:patientId/surveys/:surveyId/edit",
      name: "EditPatientSurvey",
      component: EditSurvey,
    },
    {
      path:
        "/dashboard/manage-patients/patient/:patientId/vanderbilts/:vanderbiltId",
      name: "VewVanderbilt",
      component: ViewVanderbilt,
    },
    {
      path: "/dashboard/manage-patients/patient/:patientId/surveys/:surveyId",
      name: "ViewPatientSurvey",
      component: ViewSurvey,
    },
    {
      path: "/dashboard/manage-patients/patient/:patientId/adhs-rs",
      name: "ManagePatientADHSRS",
      component: ManagePatientAdhsrs,
    },
    {
      path: "/dashboard/manage-patients/patient/:patientId/adhs-rs/new-adhs-rs",
      name: "NewADHSRS",
      component: CreatePatientAdhsrs,
    },
    {
      path:
        "/dashboard/manage-patients/patient/:patientId/adhs-rs/:adhsrsId/edit",
      name: "EditADHSRS",
      component: EditPatientAdhsrs,
    },
    {
      path:
        "/dashboard/manage-patients/patient/:patientId/surveys/:surveyId/edit",
      name: "EditPatientSurvey",
      component: EditPatientAdhsrs,
    },
    {
      path: "/dashboard/manage-patients/patient/:patientId/adhs-rs/:adhsrsId",
      name: "VewADHSRS",
      component: ViewPatientAdhsrs,
    },
    {
      path: "/dashboard/manage-hcps",
      name: "manageHcps",
      component: ManagePhysicians,
    },
    {
      path: "/dashboard/manage-practices/new-practice",
      name: "NewPractice",
      component: NewPractice,
    },
    {
      path: "/dashboard/manage-practices/practice/:practiceId",
      name: "ViewPractice",
      component: ManagePractice,
    },
    {
      path: "/dashboard/manage-practices/practice/:practiceId/new-physician",
      name: "NewPhysician",
      component: NewPhysician,
    },
    {
      path: "/dashboard/manage-hcps/new-hcp",
      name: "NewPhysician",
      component: NewPhysician,
    },
    {
      path: "/dashboard/manage-practices/physician/:physicianId",
      name: "ViewPhysician",
      component: VewPhysician,
    },
    {
      path: "/dashboard/SPRCharts/:patientId",
      name: "SPRChart",
      component: ViewSPRChart,
    },
    {
      path: "/dashboard/pr-draft",
      name: "PRDraft",
      component: PerformanceReportDraft,
    },
    {
      path: "/dashboard/adherence-draft",
      component: AdherenceDraft,
      name: "Adherence Draft",
    },
    {
      path: "/dashboard/activity-draft",
      name: "MPR List",
      component: MPRList,
    },
    {
      path: "/dashboard/docs-errors",
      name: "MPR/SPRErrors",
      component: DocumentsErrors,
    },
    {
      path: "/dashboard/inventory/:id",
      name: "Headset",
      component: Headset,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  children: [
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  component: Landing,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [dashboardsRoutes];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [dashboardsRoutes, authRoutes];
