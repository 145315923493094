import * as React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import { Settings } from "@mui/icons-material";
import { signOut } from "../redux/actions/authActions";
import { AppStateType } from "../redux/reducers";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const { user } = useSelector((state: AppStateType) => state.authReducer);
  const { physician } = useSelector(
    (state: AppStateType) => state.physicianReducer
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = () => {
    // TODO: Clear the auth cookies here inside the dispatched action
    dispatch(signOut());
    history.push("/");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Settings style={{ transform: "scale(1.3)" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {window.location.pathname !== "/dashboard" && (
          <MenuItem onClick={() => history.push("/dashboard")}>
            Dashboard
          </MenuItem>
        )}
        {(user?.role === "physician" || user?.role === "superAdmin") && (
          <MenuItem
            onClick={() => history.push("/dashboard/patient-script")}
            disabled={
              user.role === "physician" && !physician?.hcpRx2AmsAuthorized
            }
          >
            Patient Script Info
          </MenuItem>
        )}

        {user?.role === "delegate" && (
          <MenuItem
            onClick={() => history.push("/dashboard/manage-caregiver-profile")}
          >
            Manage Caregiver
          </MenuItem>
        )}
        {user?.role === "delegate" && (
          <MenuItem onClick={() => history.push("/dashboard/manage-patient")}>
            Manage Patient
          </MenuItem>
        )}
        {user?.role === "superAdmin" && (
          <MenuItem onClick={() => history.push("/dashboard/manage-practices")}>
            Manage Practices
          </MenuItem>
        )}
        {user.role === "superAdmin" && (
          <MenuItem onClick={() => history.push("/dashboard/manage-hcps")}>
            View HCP's
          </MenuItem>
        )}
        {user?.role === "physician" && (
          <MenuItem
            onClick={() => history.push("/dashboard/manage-physician-profile")}
          >
            Manage Profile
          </MenuItem>
        )}

        {user?.role === "superAdmin" && (
          <MenuItem onClick={() => history.push("/dashboard/activity")}>
            Activity
          </MenuItem>
        )}

        {(user?.role === "superAdmin" || user?.role === "practiceAdmin") && (
          <MenuItem onClick={() => history.push("/dashboard/docs-errors")}>
            MPR/SPR Errors
          </MenuItem>
        )}

        {(user?.role === "superAdmin" || user.role === "actAdmin") && (
          <MenuItem onClick={() => history.push("/dashboard/reports")}>
            Reports
          </MenuItem>
        )}
        {(user?.role === "superAdmin" || user.role === "actAdmin") && (
          <MenuItem onClick={() => history.push("/dashboard/inventory")}>
            Inventory
          </MenuItem>
        )}
        {user.role === "superAdmin" && (
          <MenuItem onClick={() => history.push("/dashboard/pr-draft")}>
            Performance Report Draft
          </MenuItem>
        )}
        {user.role === "superAdmin" && (
          <MenuItem onClick={() => history.push("/dashboard/adherence-draft")}>
            Adherence Report Draft
          </MenuItem>
        )}
        {(user.role === "superAdmin" || user.role === "actAdmin") && (
          <MenuItem onClick={() => history.push("/dashboard/activity-draft")}>
            Activity Draft
          </MenuItem>
        )}
        {user.role === "superAdmin" && (
          <MenuItem onClick={() => history.push("/dashboard/alert-draft")}>
            Alert Draft
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
