import { useQuery,useMutation } from "react-query";
import { PhysicianI } from "../types/Physician";
import { ArrayResponseI } from "../types/ResponseTypes";
import { client2 } from "../utils/axios";

export function fetchPhysician(
  physicianId: string | number 
): Promise<PhysicianI> {
  return client2
    .get<PhysicianI>(`/physicians/${physicianId}`)
    .then(({ data }) => {
      return {
        ...data,
        specialty: data.specialty === "null" ? null : data.specialty,
      };
    });
}
export function useFetchPhysician(physicianId: string | number) {
    return useQuery(
    ["fetchPhysician", physicianId],
    () => fetchPhysician(physicianId),
      {
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );
}

export function updatePhysician(physician: PhysicianI): Promise<PhysicianI> {
  return client2.put(`/physicians/${physician.id}`, physician);
}

export function fetchPhysicians(): Promise<PhysicianI[]> {
  return client2
    .get<ArrayResponseI<PhysicianI>>(`/physicians`)
    .then((response) => response.data.content);
}

export function useFetchPhysiciansQuery() {
  return useQuery(
    ["fetchPhysicians", { order: "ASC", orderBy: "name" }],
    () => fetchPhysicians(),
    {
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );
}
export function useUpdatePhysician(){
    return useMutation({
    mutationKey: "updatePhysician",
    mutationFn: (physician:PhysicianI) => {
      return updatePhysician(physician);
    },
  });
}
