import * as React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  makeStyles,
} from "@material-ui/core";

interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  button: boolean | undefined;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)<ListItemProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(4)}px;
  padding-right: ${(props) => props.theme.spacing(4)}px;

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

const useStyles = makeStyles((theme) => ({
  itemSpanText: {
    fontSize: 16,
    textAlign: "center",
  },
  link: {
       textDecoration: "none",
       color: "inherit"
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <Wrapper id="footer">
      <Grid container direction="column" spacing={0}>
        <Grid
          item
          container
          xs={12}
          md={12}
          justify="center"
          alignContent="center"
          component={List}
        >
          <ListItem button>
            <ListItemText classes={{ primary: classes.itemSpanText }}>
              <a
                target="_blank"
                className={classes.link}
                href="http://atentiv.com/privacy-policy-2/"
              >
                Privacy Policy
              </a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemText classes={{ primary: classes.itemSpanText }}>
              <a
                target="_blank"
                className={classes.link}
                href="http://atentiv.com/terms-of-use/"
              >
                Terms of Use
              </a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemText classes={{ primary: classes.itemSpanText }}>
              <a className={classes.link}>HIPPA</a>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          justify="center"
          alignContent="center"
        >
          <List>
            <ListItem button={true}>
              <ListItemText
                classes={{ primary: classes.itemSpanText }}
                primary={`Atentiv LLC ©${new Date().getFullYear()}`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      {/* <Grid container spacing={0}></Grid> */}
    </Wrapper>
  );
}

export default Footer;
