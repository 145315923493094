import React from "react";

import Loader from "../../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { AppStateType } from "../../../redux/reducers";
import { fetchPhysicianAction } from "../../../redux/actions/physicianActions";
import { signIn } from "../../../redux/actions/authActions";

function AppStarter(props: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element {
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppStateType) => state.authReducer);
  const { isPhysicianLoading } = useSelector(
    (state: AppStateType) => state.physicianReducer
  );

  React.useEffect(() => {
    if (Object.keys(user).length === 0) {
      
      dispatch(signIn());
    } else if (user.role === "physician") {
      dispatch(fetchPhysicianAction(user.id));
    }
  }, [dispatch, user]);

  if (Object.keys(user).length === 0) {
    return (
      <React.Fragment>
        <Loader />
      </React.Fragment>
    );
  }

  if (user.role === "physician" && isPhysicianLoading) {
    return (
      <React.Fragment>
        <Loader />
      </React.Fragment>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

export default AppStarter;
