import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import patientsReducer from "./patientsReducer";
import physicianReducer from "./physicianReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  patientsReducer,
  physicianReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
