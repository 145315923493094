import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../types/styles";
import Header from "../components/AppBar";
import Footer from "../components/Footer";

import { spacing } from "@material-ui/system";
import {
  Box,
  Container,
  CssBaseline,
  Link,
  Paper as MuiPaper,
  withWidth,
  useMediaQuery,
  Theme
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    min-height: 320px;
    background-image: ${(props) =>
      props.theme.palette.background.backgroundImage};
    background-attachment: fixed;
    background-size: cover;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

const Logo = styled.img`
  display: block;
  width: 200px;
`;

type DashboardPropsType = {
  width: "md" | "xs" | "sm" | "lg" | "xl";
};


const Dashboard: React.FC<DashboardPropsType> = (props) => {
  const { children, width }=props
  const history = useHistory();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <Header/>
          <MainContent p={matchesSM ? 1 : 5} pt={3} pb={2}>
          <Container maxWidth="xl">{children as JSX.Element}</Container>
        </MainContent>
        <Box display="flex" justifyContent="center" pb={2}>
          <Link href="#" onClick={() => history.push("/dashboard")}>
            <Logo
              alt="Atentiv Logo"
              src="/static/img/logo-atentivmynd-blue.png"
            />
          </Link>
        </Box>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default withWidth()(Dashboard);
