import * as types from "../../constants";
import { AppDispatchType } from "../store";
import { signIn as authSignIn } from "../../services/authService";
import { UserI } from "../../types/User";

export function signIn() {
  return (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn()
      .then((response: UserI) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          user: response,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function toggleUserRoleToPhysicianFromSuperAdmin() {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_SUPER_ADMIN });
  };
}

export function toggleUserRoleToPhysicianFromActAdmin(){
  return async (dispatch: AppDispatchType) => {
    dispatch({type:types.TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_ACT_ADMIN})
  }
}

export function toggleUserRoleToSuperAdmin() {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.TOGGLE_USER_ROLE_TO_SUPER_ADMIN });
  };
}
export function toggleUserRoleToActAdmin() {
  return async (dispatch: AppDispatchType) => {
    dispatch({type:types.TOGGLE_USER_ROLE_TO_ACT_ADMIN})
  }
}

export function signOut() {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function setKeycloak(keycloak: any) {
  return (dispatch: AppDispatchType) => {
    dispatch({
      type: types.AUTH_SIGN_IN_SUCCESS,
      keycloak,
    });
  };
}
