import React from 'react'
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon
} from "@material-ui/core";
import { Link,useLocation } from "react-router-dom";
import { Menu as MenuIcon, PowerSettingsNew } from "@mui/icons-material";
import { makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import { useSelector,useDispatch } from "react-redux";
import { AppStateType } from "../redux/reducers";
import { signOut } from "../redux/actions/authActions";



const useStyles = makeStyles((theme: AugmentedTheme) => ({
  toolbarMargin: {
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.4rem",
    },
  },

  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "35px",
    width: "35px",
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
    width: 250,
    [theme.breakpoints.up("sm")]: {
      width: 210,
    },
  },
  drawerItem: {
    color: "white",
    opacity: "0.8",
  },
  drawerItemSelected: {
    opacity: "1",
  },
}));

const Drawer = () => {
    const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
    const [selectedValue, setSelectedValue] = React.useState("/dashboard");
    const { user } = useSelector((state: AppStateType) => state.authReducer);
    const dispatch = useDispatch();
      const { physician } = useSelector(
        (state: AppStateType) => state.physicianReducer
    );
    const location = useLocation();

    React.useEffect(() => {
        setSelectedValue(location.pathname);
    }, [location.pathname])

    const handleDrawerClose = (e: React.MouseEvent) => {
        setOpenDrawer(false);
    }
      const handleSignOut = () => {
        // TODO: Clear the auth cookies here inside the dispatched action
        dispatch(signOut());
    
      };
    return (
      <>
        <SwipeableDrawer
          classes={{ paper: classes.drawer }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          onOpen={() => setOpenDrawer(true)}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <div className={classes.toolbarMargin} />
          <List disablePadding>
            <ListItem
              onClick={handleDrawerClose}
              selected={selectedValue === "/dashboard"}
              component={Link}
              to="/dashboard"
              divider
              button
            >
              <ListItemText className={classes.drawerItem} disableTypography>
                Dashboard
              </ListItemText>
            </ListItem>
            {(user?.role === "physician" || user?.role === "superAdmin") && (
              <ListItem
                onClick={handleDrawerClose}
                selected={selectedValue === "/dashboard/patient-script"}
                component={Link}
                to="/dashboard/patient-script"
                divider
                button
                disabled={
                  user.role === "physician" && !physician?.hcpRx2AmsAuthorized
                }
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Patient Script Info
                </ListItemText>
              </ListItem>
            )}
            {user?.role === "delegate" && (
              <ListItem
                selected={
                  selectedValue === "/dashboard/manage-caregiver-profile"
                }
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/manage-caregiver-profile"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Manage Caregiver
                </ListItemText>
              </ListItem>
            )}
            {user?.role === "delegate" && (
              <ListItem
                selected={selectedValue === "/dashboard/manage-patient"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/manage-patient"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Manage Patient
                </ListItemText>
              </ListItem>
            )}
            {user.role === "superAdmin" && (
              <ListItem
                selected={selectedValue === "/dashboard/manage-practices"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/manage-practices"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Manage Practices
                </ListItemText>
              </ListItem>
            )}
            {user.role === "superAdmin" && (
              <ListItem
                selected={selectedValue === "/dashboard/manage-hcps"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/manage-hcps"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  View HCP's
                </ListItemText>
              </ListItem>
            )}
            {user?.role === "physician" && (
              <ListItem
                selected={
                  selectedValue === "/dashboard/manage-physician-profile"
                }
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/manage-physician-profile"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Manage Profile
                </ListItemText>
              </ListItem>
            )}

            {user?.role === "superAdmin" && (
              <ListItem
                selected={selectedValue === "/dashboard/activity"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/activity"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Activity
                </ListItemText>
              </ListItem>
            )}
            {(user?.role === "superAdmin" ||
              user?.role === "practiceAdmin") && (
              <ListItem
                selected={selectedValue === "/dashboard/docs-errors"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/docs-errors"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  MPR/SPR Errors
                </ListItemText>
              </ListItem>
            )}
            {user.role === "superAdmin" && (
              <ListItem
                selected={selectedValue === "/dashboard/pr-draft"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/pr-draft"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Performance Report Draft
                </ListItemText>
              </ListItem>
            )}
            {user.role === "superAdmin" && (
              <ListItem
                selected={selectedValue === "/dashboard/adherence-draft"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/adherence-draft"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Adherence Report Draft
                </ListItemText>
              </ListItem>
            )}
            {(user?.role === "superAdmin" || user.role === "actAdmin") && (
              <ListItem
                selected={selectedValue === "/dashboard/reports"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/reports"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Reports
                </ListItemText>
              </ListItem>
            )}
            {(user?.role === "superAdmin" || user.role === "actAdmin") && (
              <ListItem
                selected={selectedValue === "/dashboard/inventory"}
                onClick={handleDrawerClose}
                divider
                button
                component={Link}
                to="/dashboard/inventory"
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Inventory
                </ListItemText>
              </ListItem>
            )}

            <ListItem onClick={handleSignOut} divider button>
              <ListItemText
                style={{ flex: "0.1 1 auto" }}
                className={classes.drawerItem}
                disableTypography
              >
                Signout
              </ListItemText>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <IconButton
          className={classes.drawerIconContainer}
          disableRipple
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon className={classes.drawerIcon} />
        </IconButton>
      </>
    );
}

export default Drawer