import { AppDispatchType } from "../store";
import * as types from "../../constants";
import { fetchPhysician } from "../../services/physicianService";

export function fetchPhysicianAction(id: string | number) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.PHYSICIAN_FETCH_REQUEST });

    return fetchPhysician(id)
      .then((response) => {
        dispatch({
          type: types.PHYSICIAN_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: types.PHYSICIAN_FETCH_FAILURE });
      });
  };
}
