import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Keycloak from "keycloak-js";

import { Provider } from "react-redux";
import store from "./redux/store/index";
import { setKeycloak } from "./redux/actions/authActions";

let initOptions = {
  url: process.env.REACT_APP_AUTH_URL,
  realm: process.env.REACT_APP_AUTH_REALM,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
};
const keycloak = new Keycloak(initOptions);
window.kc = keycloak;

keycloak
  .init({ onLoad: "login-required" })
  .success((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.info("Authenticated");
    }

    setKeycloak(keycloak)(store.dispatch);

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );

    localStorage.setItem("react-token", keycloak.token);
    localStorage.setItem("react-refresh-token", keycloak.refreshToken);

    setTimeout(() => {
      keycloak
        .updateToken(30)
        .success((refreshed) => {
          if (refreshed) {
            console.log("Token refreshed" + refreshed);
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .error(() => {
          console.error("Failed to refresh token");
        });
    }, 60000);
  })
  .error((err) => {
    console.error(err);
    console.error("Authentication Failed");
  });

export { keycloak };
