import { keycloak } from "../..";
import * as types from "../../constants";
import { UserI } from "../../types/User";

export type AuthType = {
  keycloak?: any;
  user: UserI;
  toggledFrom?: "superAdmin" | "actAdmin" | string;
};

export type AuthActionsType = {
  keycloak?: any;
  user?: UserI;
  type: string;
};

export default function reducer(
  state: AuthType = { user: {} as UserI },
  actions: AuthActionsType
): AuthType {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        keycloak: actions.keycloak || state.keycloak,
        user: actions.user || state.user,
      };

    case types.AUTH_SIGN_OUT:
      keycloak.clearToken();
      keycloak.logout();
      return {
        ...state,
        user: {} as UserI,
      };
    case types.TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_SUPER_ADMIN:
      console.log("toggled");

      return {
        ...state,
        user: { ...state.user, role: "physician" },
        toggledFrom: "superAdmin",
      };
    case types.TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_ACT_ADMIN:
      return {
        ...state,
        user: { ...state.user, role: "physician" },
        toggledFrom: "actAdmin",
      };
    case types.TOGGLE_USER_ROLE_TO_SUPER_ADMIN:
      return { ...state, user: { ...state.user, role: "superAdmin" } };
    case types.TOGGLE_USER_ROLE_TO_ACT_ADMIN:
      return {...state,user:{...state.user,role:"actAdmin"}}
    default:
      return state;
  }
}
