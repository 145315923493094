import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  presentationLayoutRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../pages/auth/Page404";
import { RouteType } from "../types/routes";
import AppStarter from "../pages/presentation/Landing/AppStarter";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) => {

  return routes.map(
    ({ component: Component, guard, children, path, exact }, index: number) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children.map((element, index: number) => {
          const Guard = element.guard || React.Fragment;

            return (
              <Route
                key={index}
                path={element.path}
                exact
                render={(props: RouteComponentProps) => {
                    return (
                      <Guard>
                        <Layout>
                          <element.component {...props}    />
                        </Layout>
                      </Guard>
                    );
                }}
              />
            );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact={exact ?? true}
          render={(props) => (
            <Guard>
              <Layout>
                <Component {...props} />
              </Layout>
            </Guard>
          )}
        />
      ) : null;
    }
  );  
}


const Routes = () => (
  <Router>
    <AppStarter>
      <Switch>
        {childRoutes(PresentationLayout, presentationLayoutRoutes)}
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </AppStarter>
  </Router>
);

export default Routes;
