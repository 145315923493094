import axios, { AxiosResponse } from "axios";
import { keycloak } from "../index";

const client = axios.create({
  baseURL: "https://api.stage-future.atentiv.com",
});
const client2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const responseInterceptor = function (response: AxiosResponse<any>) {
  return response;
};
const errorInterceptor = function (error: any) {
  return Promise.reject(error.response);
};

client.interceptors.response.use(responseInterceptor, errorInterceptor);
client2.interceptors.response.use(responseInterceptor, errorInterceptor);
client2.interceptors.request.use(function (request) {
  request.headers["Authorization"] = `Bearer ${keycloak.token}`;
  return request;
});

export { client2 };
export default client;
