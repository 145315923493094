// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";

// TODO: remove these actions
// Patients
export const PATIENTS_FETCH_REQUEST = "PATIENTS_FETCH_REQUEST";
export const PATIENTS_FETCH_SUCCESS = "PATIENTS_FETCH_SUCCESS";
export const PATIENTS_FETCH_FAILURE = "PATIENTS_FETCH_FAILURE";
export const PATIENT_MPR_FETCH_REQUEST = "PATIENT_MPR_FETCH_REQUEST";
export const PATIENT_MPR_FETCH_SUCCESS = "PATIENT_MPR_FETCH_SUCCESS";
export const PATIENT_MPR_FETCH_FAILURE = "PATIENT_MPR_FETCH_FAILURE";

//toggle User Role
export const TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_SUPER_ADMIN = "TOGGLE_USER_ROLE_TO_PHYSICIAN"
export const TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_ACT_ADMIN="TOGGLE_USER_ROLE_TO_PHYSICIAN_FROM_ACT_ADMIN"
export const TOGGLE_USER_ROLE_TO_SUPER_ADMIN = "TOGGLE_USER_ROLE_TO_SUPER_ADMIN"
export const TOGGLE_USER_ROLE_TO_ACT_ADMIN="TOGGLE_USER_ROLE_TO_ACT_ADMIN"
// Patient Reducer Actions
export const PATIENT_FETCH_REQUEST = "PATIENTS_FETCH_REQUEST";
export const PATIENT_FETCH_SUCCESS = "PATIENTS_FETCH_SUCCESS";
export const PATIENT_FETCH_FAILURE = "PATIENTS_FETCH_FAILURE";

// Caregiver Reducer Actions
export const CAREGIVER_FETCH_REQUEST = "CAREGIVER_FETCH_REQUEST";
export const CAREGIVER_FETCH_SUCCESS = "CAREGIVER_FETCH_SUCCESS";
export const CAREGIVER_FETCH_FAILURE = "CAREGIVER_FETCH_FAILURE";

// Caregiver Reducer Actions
export const PHYSICIAN_FETCH_REQUEST = "PHYSICIAN_FETCH_REQUEST";
export const PHYSICIAN_FETCH_SUCCESS = "PHYSICIAN_FETCH_SUCCESS";
export const PHYSICIAN_FETCH_FAILURE = "PHYSICIAN_FETCH_FAILURE";

// Practice Reducer Actions
export const PRACTICE_FETCH_REQUEST = "PRACTICE_FETCH_REQUEST";
export const PRACTICE_FETCH_SUCCESS = "PRACTICE_FETCH_SUCCESS";
export const PRACTICE_FETCH_FAILURE = "PRACTICE_FETCH_FAILURE";

//variables
export const ROLE = {
  PHYSICIAN: "physician",
  PARENT: "delegate",
  ADMIN: "superAdmin",
  DOCTOR: "doctor",
};
