import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import logger from 'redux-logger'
import { rootReducer } from "../reducers/index";

const composeEnhancers = composeWithDevTools({});
const middleWares=[thunk,logger]
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleWares))
);

export type AppDispatchType = typeof store.dispatch;

export default store;
