import * as types from "../../constants";
import { PhysicianI } from "../../types/Physician";

export type PhysicianReducerStateT = {
  physician?: PhysicianI;
  isPhysicianLoading: boolean;
  isPhysicianLoadingError: boolean;
};

export type PhysicianReducerActionT = {
  type: string;
  payload: any;
};

export default function reducer(
  state: PhysicianReducerStateT = {
    isPhysicianLoading: true,
    isPhysicianLoadingError: false,
  },
  action: PhysicianReducerActionT
): PhysicianReducerStateT {
  switch (action.type) {
    case types.PHYSICIAN_FETCH_REQUEST:
      return {
        ...state,
        isPhysicianLoadingError: false,
        isPhysicianLoading: true,
      };

    case types.PHYSICIAN_FETCH_SUCCESS:
      return {
        ...state,
        isPhysicianLoadingError: false,
        isPhysicianLoading: false,
        physician: action.payload,
      };

    case types.PHYSICIAN_FETCH_FAILURE:
      return {
        ...state,
        isPhysicianLoadingError: true,
        isPhysicianLoading: false,
      };

    default:
      return state;
  }
}
