import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { useTheme } from "@material-ui/core/styles";
import {
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  Link as MuiLink,
  useMediaQuery,
} from "@material-ui/core";
import Drawer from "./Drawer";
import UserDropdown from "./UserDropdown";
import { useHistory } from "react-router-dom";
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const Logo = styled.img`
  display: block;
  width: 200px;
`;

type AppBarProps = {
  theme: {};
};



const AppBarComponent: React.FC<AppBarProps> = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  console.log("rerendering")
  
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={10} sm={2}>
            <MuiLink href="#" onClick={() => history.push("/dashboard")}>
              <Logo
                alt="Atentiv Logo"
                src="/static/img/Atentiv_Mynd_Solution_Logo_RGB.png"
              />
            </MuiLink>
          </Grid>

          <Grid item sm={7} />

          <Grid item xs={2} sm={3}>
            <Box display="flex" justifyContent="flex-end">
              {!matchesSM && (
                <MuiLink href="#" onClick={() => history.push("/dashboard")}>
                  <Logo
                    alt="Atentiv Logo"
                    src="/static/img/logo-atentivmynd-blue.png"
                  />
                </MuiLink>
              )}
              <Box display="flex" justifyContent="flex-end" pl={2}>
                {matchesSM ? <Drawer  />:<UserDropdown/>}  
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withTheme(AppBarComponent);
